import { NgIf } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { M3CalculatorService } from 'app/_services/m3-calculator.service'
import { OrderworkService } from 'app/_services/orderwork.service'
import { M3ObjectsTabsetComponent } from 'app/m3-calculator/objects-tabset/objects-tabset.component'
import { M3TotalComponent } from 'app/m3-calculator/total/total.component'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { ToastrService } from 'ngx-toastr'

@Component({
    selector: 'app-m3-calculator-pre',
    templateUrl: './m3-calculator-pre.component.html',
    standalone: true,
    imports: [NgIf, M3ObjectsTabsetComponent, M3TotalComponent, FontAwesomeModule, BsDropdownModule],
})
export class M3CalculatorPreComponent implements OnInit {
    // For m3 calculator
    form
    m3Object
    language

    // While loading
    isSubmitting = false

    constructor(
        private fb: UntypedFormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private m3CalculatorService: M3CalculatorService,
        private orderworkService: OrderworkService,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        this.initLanguage().then(() => this.initForms())
    }

    async initLanguage() {
        const params = this.activatedRoute.snapshot.params
        const queryParams = this.activatedRoute.snapshot.queryParams

        const res = await this.m3CalculatorService.read(params, queryParams).toPromise()
        const data = res.data

        this.language = data.language
        this.m3Object = data.m3
    }

    async initForms() {
        this.form = this.fb.group(this.m3CalculatorService.createM3CategoryGroupHolder(this.m3Object))
    }

    async goToLanguage(language) {
        if (this.language.id === language) return
        await this.router.navigateByUrl(this.router.url.replace(this.language.id, language))
        this.initLanguage()
    }

    async submit() {
        this.isSubmitting = true

        try {
            // Make orderwork
            const params = this.activatedRoute.snapshot.params
            const queryParams = this.activatedRoute.snapshot.queryParams
            const res0 = await this.orderworkService.read('removal', params, queryParams, true).toPromise()

            const orderwork = await res0.data.orderwork
            const orderworkId = orderwork._id

            // Update orderwork with m3 calculator
            orderwork.estimate.m3Calculator = this.form.value
            await this.orderworkService.save(orderwork).toPromise()

            // Finished, go to next step
            this.doRedirect(orderworkId)

            this.isSubmitting = false
        } catch (ex) {
            this.toastrService.error(ex.message)
            this.isSubmitting = false
        }
    }

    /** Redirect user to removal-step component with current orderworkId */
    doRedirect(orderworkId) {
        // Redirects
        const affiliate = this.activatedRoute.snapshot.params.affiliate
        const newUrl = `/form/removal/${this.language.id}/${affiliate}${`&orderworkId=${orderworkId}`}`
        window.top.location.href = newUrl
    }
}
